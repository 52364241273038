










import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InlineSvg from 'vue-inline-svg'

export default Vue.extend({
	name: 'SvgIcon',
	components: {
		InlineSvg,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		width: {
			type: Number,
			default: null,
		},
		height: {
			type: Number,
			default: null,
		},
		size: {
			type: Number,
			default: null,
		},
		color: {
			type: String,
			default: undefined,
		},
		strokeColor: {
			type: String,
			default: undefined,
		},
	},
})

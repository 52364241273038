import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=3d8fe4fe&scoped=true&"
import script from "./Chat.vue?vue&type=script&lang=ts&"
export * from "./Chat.vue?vue&type=script&lang=ts&"
import style0 from "./Chat.vue?vue&type=style&index=0&id=3d8fe4fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules_dev/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d8fe4fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/vercel/path0/components/elements/SvgIcon.vue').default,ChatMessages: require('/vercel/path0/components/chat/ChatMessages.vue').default,LoadingIndicator: require('/vercel/path0/components/elements/LoadingIndicator.vue').default,GroupChatMessages: require('/vercel/path0/components/chat/GroupChatMessages.vue').default})

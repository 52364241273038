
































































































































































































































































































import Vue from 'vue'
import moment from 'moment'
import { VTooltip } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)

let updateSearchTimeout: any
let searchClearingTimeout: any

export default Vue.extend({
	name: 'NavBar',
	middleware: 'auth',
	props: {
		isLogin: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuExpanded: false,
			fullMenuVisible: false,
			searchExpanded: false,
			searchVisible: false,
			notificationCenterVisible: false,
			// eslint-disable-next-line global-require
			menuButton: require('@/assets/hamburger.svg'),
			// eslint-disable-next-line global-require
			closeButton: require('@/assets/close-btn.svg'),
			slimMenuBar: false,
			formEmail: '',
			formPassword: '',
			isLoading: false,
			searchValue: '',
			companySearchResults: [] as Array<any>,
			channelSearchResults: [] as Array<any>,
			userSearchResults: [] as Array<any>,
			searchResultsTypeSelection: 'companies',
			navigationSearchResults: [] as Array<any>,
			salutation: 'Good Evening',
			notifications: [] as Array<any>,
			showReadNotifications: false,
			moreNotificationsAvailable: false,
			now: null as any,
			unreadNotifications: 0,
			knownUnreadNotifications: 0,
			showTooltip: false,
			keyboardSelectionIndex: null as any,
		}
	},
	watch: {
		notificationCenterVisible(value) {
			if (value) {
				if (this.searchVisible) {
					this.toggleSearch()
				} else if (this.fullMenuVisible) {
					this.toggleMenu()
				}
			} else {
				this.knownUnreadNotifications = this.unreadNotifications
			}

			if (value) {
				this.$nuxt.$emit(value ? 'disableScroll' : 'restoreScroll')
			}
		},
		searchValue() {
			this.updateSearch()
		},
	},
	beforeMount() {
		window.addEventListener('scroll', this.handleScroll)

		document.addEventListener('keyup', (evt) => {
			if (evt.keyCode === 27) {
				if (this.menuExpanded) {
					this.toggleMenu()
				} else if (this.searchVisible) {
					this.toggleSearch()
				} else if (this.notificationCenterVisible) {
					this.notificationCenterVisible = false
				}
			}
		})

		this.updateSalutation()

		this.$i18n.onLanguageSwitched = () => {
			this.updateSalutation()
		}

		document.addEventListener('focus', this.updateSalutation)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		toggleMenu() {
			if (this.notificationCenterVisible) {
				this.notificationCenterVisible = false
				setTimeout(this.toggleMenu, 500)
				return
			}

			if (this.searchVisible) {
				this.toggleSearch()
				setTimeout(this.toggleMenu, 500)
				return
			}

			if (!this.menuExpanded) {
				this.menuExpanded = true

				setTimeout(() => {
					this.fullMenuVisible = true
				}, 100)
			} else {
				this.fullMenuVisible = false

				setTimeout(() => {
					this.menuExpanded = false
				}, 600)
			}
		},
		toggleSearch() {
			clearTimeout(searchClearingTimeout)

			this.searchResultsTypeSelection = 'companies'

			if (this.fullMenuVisible) {
				this.toggleMenu()
				setTimeout(this.toggleSearch, 500)
				return
			}

			if (this.notificationCenterVisible) {
				this.notificationCenterVisible = false
				setTimeout(this.toggleSearch, 500)
				return
			}

			if (!this.searchExpanded) {
				this.searchExpanded = true

				setTimeout(() => {
					this.searchVisible = true;
					(<any> this.$refs.searchInput).focus()
				}, 100)

				document.addEventListener('keydown', this.keyDown)
			} else {
				document.removeEventListener('keydown', this.keyDown)
				this.searchVisible = false

				setTimeout(() => {
					this.searchExpanded = false
					searchClearingTimeout = setTimeout(() => {
						this.searchValue = ''
						this.companySearchResults = []
						this.channelSearchResults = []
						this.userSearchResults = []
						this.navigationSearchResults = []
					}, 2500)
				}, 600)
			}
		},
		handleScroll() {
			if (!this.slimMenuBar && window.pageYOffset > 100) {
				this.slimMenuBar = true
			} else if (this.slimMenuBar && window.pageYOffset < 50) {
				this.slimMenuBar = false
			}
		},
		updateNotificationStatus(status: number) {
			this.unreadNotifications = status

			if (this.knownUnreadNotifications > this.unreadNotifications) {
				this.knownUnreadNotifications = this.unreadNotifications
			} else if (this.knownUnreadNotifications < this.unreadNotifications) {
				this.showTooltip = true
				setTimeout(() => {
					this.showTooltip = false
				}, 5000)
			}
		},
		keyDown(event: KeyboardEvent) {
			if (event.keyCode === 40) {
				event.preventDefault()
				this.keyboardSelectionIndex = this.keyboardSelectionIndex !== null
					? this.keyboardSelectionIndex + 1
					: 0

				switch (this.searchResultsTypeSelection) {
					case 'companies':
						if (!this.companySearchResults[this.keyboardSelectionIndex]) {
							this.keyboardSelectionIndex = 0
						}
						break

					case 'channels':
						if (!this.channelSearchResults[this.keyboardSelectionIndex]) {
							this.keyboardSelectionIndex = 0
						}
						break

					case 'user':
						if (!this.userSearchResults[this.keyboardSelectionIndex]) {
							this.keyboardSelectionIndex = 0
						}
						break

					default:
						break
				}
			} else if (event.keyCode === 38) {
				event.preventDefault()

				if (this.keyboardSelectionIndex === 0) {
					this.keyboardSelectionIndex = null
				} else if (this.keyboardSelectionIndex && this.keyboardSelectionIndex > 0) {
					this.keyboardSelectionIndex -= 1
				}
			} else if (event.keyCode === 13 && this.keyboardSelectionIndex !== null) {
				event.preventDefault()

				switch (this.searchResultsTypeSelection) {
					case 'companies':
						if (this.companySearchResults[this.keyboardSelectionIndex]) {
							this.toggleSearch()
							this.$router.push(`/company/${this.companySearchResults[this.keyboardSelectionIndex].within_id}`)
						}
						break

					case 'channels':
						if (this.channelSearchResults[this.keyboardSelectionIndex]) {
							this.toggleSearch()
							this.$router.push(`/channel/${this.channelSearchResults[this.keyboardSelectionIndex].id}`)
						}
						break

					case 'user':
						if (this.userSearchResults[this.keyboardSelectionIndex]) {
							this.toggleSearch()
							this.$router.push(`/profile/${this.userSearchResults[this.keyboardSelectionIndex].id}`)
						}
						break

					default:
						break
				}
			}
		},
		logout() {
			this.$auth.logout()
		},
		search() {
			this.isLoading = true
			this.keyboardSelectionIndex = null

			if (!this.searchValue.length) {
				this.companySearchResults = []
				this.channelSearchResults = []
				this.userSearchResults = []
				return
			}

			this.$axios.get('/api/search', {
				params: {
					query: this.searchValue,
				},
			}).then((response) => {
				if (response.data.companies) {
					this.companySearchResults = response.data.companies
				} else {
					this.companySearchResults = []
				}

				if (response.data.channels) {
					this.channelSearchResults = response.data.channels
				} else {
					this.channelSearchResults = []
				}

				if (response.data.profiles) {
					this.userSearchResults = response.data.profiles
				} else {
					this.userSearchResults = []
				}

				if (!this.companySearchResults.length && this.searchResultsTypeSelection === 'companies') {
					this.searchResultsTypeSelection = !this.channelSearchResults.length && this.userSearchResults.length
						? 'user'
						: 'channels'
				}

				if (!this.channelSearchResults.length && this.searchResultsTypeSelection === 'channels') {
					this.searchResultsTypeSelection = !this.companySearchResults.length && this.userSearchResults.length
						? 'user'
						: 'companies'
				}

				if (!this.userSearchResults.length && this.searchResultsTypeSelection === 'user') {
					this.searchResultsTypeSelection = !this.companySearchResults.length
						&& this.channelSearchResults.length
						? 'channels'
						: 'companies'
				}
			}).finally(() => {
				this.isLoading = false
			})

			const menuItems = [
				{
					name: 'Start',
					keywords: 'start',
					path: '/',
				},
				{
					name: 'My Feed',
					keywords: 'feed news',
					path: '/feed/',
				},
				{
					name: 'Companies',
					keywords: 'companies firmen übersicht overview',
					path: '/companies/',
				},
				{
					name: 'Profile',
					keywords: 'profile user',
					path: '/profile/',
				},
			]

			const navigationSearchResults : any = []

			if (this.searchValue.length > 2) {
				menuItems.forEach((menuItem) => {
					if (menuItem.keywords.includes(this.searchValue.toLowerCase())) {
						navigationSearchResults.push(menuItem)
					}
				})
			}

			this.navigationSearchResults = navigationSearchResults
		},
		updateSearch() {
			this.isLoading = true

			if (this.searchValue.length < 3) {
				this.companySearchResults = []
				this.navigationSearchResults = []
				return
			}

			clearTimeout(updateSearchTimeout)
			updateSearchTimeout = setTimeout(this.search, 200)
		},
		updateSalutation() {
			const hour = parseInt(moment().format('H'), 10)

			if (hour > 17) {
				this.salutation = this.$t('salutation.evening').toString()
			} else if (hour > 11) {
				this.salutation = this.$t('salutation.day').toString()
			} else if (hour > 4) {
				this.salutation = this.$t('salutation.morning').toString()
			} else {
				this.salutation = this.$t('salutation.evening').toString()
			}
		},
		goToLogin() {
			localStorage.setItem('login_redirect', this.$router.currentRoute.fullPath)
			this.$router.push('/login/')
		},
	},
})

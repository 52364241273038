


















import Vue from 'vue'

export default Vue.extend({
	name: 'ImageLightbox',
	data() {
		return {
			show: false,
			escHandler: (e: any) => {
				if (e.keyCode === 27) {
					this.$emit('close')
				}
			},
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.show = true
		})

		document.addEventListener('keyup', this.escHandler)
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.escHandler)
	},
})

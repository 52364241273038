// eslint-disable-next-line import/prefer-default-export
export const escapeHtml = (text: string): string => {
	const map = <any>{
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		'\'': '&#039;',
	}

	return text.replace(/[&<>"']/g, (m) => map[m])
}

































import Vue from 'vue'

export default Vue.extend({
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		maxWidth: {
			type: String,
			default: null,
		},
		closeOnClickOutside: {
			type: Boolean,
			default: true,
		},
		padding: {
			type: Boolean,
			default: true,
		},
		topPadding: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			show: false,
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.show = true
			const { scrollTop } = document.documentElement
			document.documentElement.classList.add('disabled-scrolling')
			document.documentElement.style.top = `-${scrollTop}px`
		})
	},
	beforeDestroy() {
		const { top } = document.documentElement.style
		document.documentElement.classList.remove('disabled-scrolling')
		document.documentElement.style.top = '0'
		document.documentElement.scrollTop = -parseInt(top, 10)
	},
})

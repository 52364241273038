









import Vue from 'vue'

export default Vue.extend({
	props: {
		large: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
	},
})

import Vue from 'vue'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, func-names
export default function ({ $axios, app }, inject) {
	inject('authHub', new Vue())

	$axios.onError((error) => {
		const code = parseInt(error.response && error.response.status, 10)

		if (
			error.name === 'ExpiredAuthSessionError' || (
				code === 401
				&& error.response.data.status === 'error'
				&& error.response.data.code
				&& [2, 3].includes(error.response.data.code)
			) || (
				code === 403
				&& error.response.data.message === 'You\'re not logged in.'
			)
		) {
			app.$authHub.$emit('showReSignInPopup')
		}
	})
}

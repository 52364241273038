






































































































































































































































/* eslint-disable camelcase */

import Vue from 'vue'
import moment from 'moment'
import { VTooltip } from 'v-tooltip'
import { dynamicDate, staticDate } from '@/helpers/DateHelper'
import eventText from '~/helpers/GroupEventHelper'

Vue.directive('tooltip', VTooltip)

interface Conversation {
	id: string,
	type: string,
	conversation_partner: {
		id: string,
		name: string,
		profile_picture: string|null,
		online: boolean
	}|null,
	group: {
		name: string,
		description: string
	}|null,
	hidden: boolean,
	latest_message: {
		sender: string,
		date: string,
		text: string,
		read: string|null
	}|null,
	unread?: boolean
}

interface Message {
	id: string,
	date: string,
	sender: any|string,
	text: string,
	recipient: string|null,
	read: string|null
}

interface DataInterface {
	expanded: boolean,
	conversations: Array<Conversation>,
	now: any,
	selectedConversation: any,
	messages: Array<Message>|null,
	newMessage: string,
	unreadMessages: boolean,
	sendingMessage: boolean,
	lastConversationActivity: string|null,
	conversationPollingInterval: any,
	image: any,
	imageFileExtension: string|null,
	dragActive: boolean,
	tooltipContent: string,
	showTooltip: boolean,
	connection: WebSocket | null,
	reconnectTimeout: any,
}

export default Vue.extend({
	loading: false,
	data() {
		return {
			expanded: false,
			conversations: [],
			now: moment(),
			selectedConversation: null,
			messages: null,
			newMessage: '',
			unreadMessages: false,
			sendingMessage: false,
			lastConversationActivity: null,
			conversationPollingInterval: null,
			image: null,
			imageFileExtension: null,
			dragActive: false,
			tooltipContent: this.$t('chats.newMessages'),
			showTooltip: false,
			connection: null,
			reconnectTimeout: null,
		} as DataInterface
	},
	computed: {
		conversationsByLastActivity(): Array<any> {
			const sorted = this.conversations

			sorted.sort((a: Conversation, b: Conversation) => {
				if (a.latest_message && b.latest_message && a.latest_message.date > b.latest_message.date) {
					return -1
				}

				if (a.latest_message && b.latest_message && a.latest_message.date < b.latest_message.date) {
					return 1
				}

				return 0
			})

			return sorted
		},
	},
	beforeDestroy() {
		this.connection?.close()
		window.removeEventListener('focus', this.windowFocus)
	},
	mounted() {
		if (!this.$auth.loggedIn) {
			return
		}

		if (localStorage.getItem('chatExpanded')) {
			this.expanded = true
		}

		this.loadConversations()
		this.connect()
		this.updateOnlineStatus()

		setInterval(() => {
			this.now = moment()
		}, 60000)

		// setInterval(this.connect, 5000); // try to reconnect every 5s if connection lost

		window.addEventListener('focus', this.windowFocus)
	},
	methods: {
		dynamicDate,
		staticDate,
		eventText(message: any) {
			return eventText(this, message)
		},
		connect() {
			if (this.connection && this.connection.readyState === 1) {
				return
			}

			if (this.connection !== null) {
				this.loadConversations()
				this.connection.close()

				if (this.selectedConversation && this.$refs.chatMessages) {
					(<any> this.$refs.chatMessages).loadNewMessages()
				}

				this.updateOnlineStatus()
			}

			const authToken = (this.$auth.strategy as any).token.get()

			const { wsURL } = (this as any).$config
			// console.log('Trying to connect')
			this.connection = new WebSocket(`${wsURL}?auth=${encodeURIComponent(authToken)}`)

			this.connection.onmessage = (event) => {
				const message = JSON.parse(event.data)

				if (message && message.status === 'ok') {
					switch (message.action) {
						case 'send_message':
							this.sendingMessage = false
							this.newMessage = ''
							this.image = null;
							(this.$refs.chatMessages as any).addMessage(message.message, true)

							if (message.group) {
								this.conversations.forEach((conversation, index) => {
									if (
										conversation.type === 'group'
										&& conversation.id === message.group
									) {
										this.conversations[index].latest_message = message.message
									}
								})
							} else {
								this.conversations.forEach((conversation, index) => {
									if (
										conversation.conversation_partner
										&& conversation.conversation_partner.id === message.message.recipient
									) {
										this.conversations[index].latest_message = message.message
										this.conversations[index].unread = false
									}
								})
							}
							break

						default:
							break
					}
				} else if (message && message.status === 'new_message') {
					if (
						this.selectedConversation
						&& this.selectedConversation.type !== 'group'
						&& this.selectedConversation.conversation_partner.id === message.message.sender
					) {
						(<any> this.$refs.chatMessages).addMessage(message.message)
					}

					this.conversations.forEach((conversation, index) => {
						if (
							conversation.conversation_partner
							&& conversation.conversation_partner.id === message.message.sender
						) {
							this.conversations[index].latest_message = message.message
							this.conversations[index].unread = true
							this.checkForUnreadMessages()
						}
					})

					this.$forceUpdate()
				} else if (message.status === 'new_group_message') {
					if (
						this.selectedConversation
						&& this.selectedConversation.type === 'group'
						&& this.selectedConversation.id === message.group
					) {
						(this.$refs.chatMessages as any).addMessage(message.message)
					}

					this.conversations.forEach((conversation, index) => {
						if (
							conversation.type === 'group'
							&& conversation.id === message.group
						) {
							this.conversations[index].latest_message = message.message
							this.conversations[index].unread = true
							this.checkForUnreadMessages()
						}
					})

					this.$forceUpdate()
				} else if (message && message.status === 'messages_read') {
					if (
						this.selectedConversation
						&& this.selectedConversation.type !== 'group'
						&& this.selectedConversation.conversation_partner.id === message.sender
					) {
						(this.$refs.chatMessages as any).messagesWereRead(message.read)
					}

					this.conversations.forEach((conversation, index) => {
						if (
							conversation.type !== 'group'
							&& conversation.conversation_partner
							&& conversation.conversation_partner.id === message.sender
						) {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							this.conversations[index].latest_message!.read = message.read
						}
					})
				} else if (message && message.status === 'user_online') {
					this.conversations.forEach((conv) => {
						if (
							conv.type === 'direct'
							&& conv.conversation_partner
							&& conv.conversation_partner.id === message.user_id
						) {
							// eslint-disable-next-line no-param-reassign
							conv.conversation_partner.online = true
						}
					})
				} else if (message && message.status === 'user_offline') {
					this.conversations.forEach((conv) => {
						if (
							conv.type === 'direct'
							&& conv.conversation_partner
							&& conv.conversation_partner.id === message.user_id
						) {
							// eslint-disable-next-line no-param-reassign
							conv.conversation_partner.online = false
						}
					})
				} else {
					console.log('unknown message from ws', message)
				}
			}

			this.connection.onopen = () => {
				console.log('Chat connection established')
			}

			this.connection.onclose = () => {
				console.log('Chat connection closed, retrying in 10s')
				this.connection?.close()
				this.connection = null

				clearTimeout(this.reconnectTimeout)
				this.reconnectTimeout = setTimeout(this.connect, 10000)
			}
		},
		toggle() {
			this.expanded = !this.expanded
			if (this.expanded) {
				localStorage.setItem('chatExpanded', 'true')
				this.showTooltip = false
			} else {
				localStorage.removeItem('chatExpanded')
				this.closeConversation()
			}
		},
		openConversation(conversation: any) {
			// eslint-disable-next-line no-param-reassign
			conversation.unread = false
			this.selectedConversation = conversation
			this.checkForUnreadMessages()
		},
		closeConversation() {
			this.messages = null
			this.selectedConversation = null
		},
		sendMessage() {
			if (this.sendingMessage) {
				return
			}

			if (this.newMessage.length >= 1 || this.image) {
				this.sendingMessage = true

				if (this.connection && this.connection.readyState === 1) {
					if (this.selectedConversation.type === 'group') {
						this.connection.send(JSON.stringify({
							action: 'send_message',
							message: {
								group: this.selectedConversation.id,
								text: this.newMessage,
								image: this.image,
								image_file_extension: this.imageFileExtension,
							},
						}))
					} else {
						this.connection.send(JSON.stringify({
							action: 'send_message',
							message: {
								recipient: this.selectedConversation.conversation_partner.id,
								text: this.newMessage,
								image: this.image,
								image_file_extension: this.imageFileExtension,
							},
						}))
					}
				} else {
					this.$axios.post(
						this.selectedConversation.type === 'group'
							? `/api/chat/group/${this.selectedConversation.id}/message`
							: `/api/chat/with/${this.selectedConversation.conversation_partner.id}/message`,
						{
							text: this.newMessage,
							image: this.image,
							image_file_extension: this.imageFileExtension,
						},
					).then((res) => {
						this.newMessage = ''
						this.image = null;
						(this.$refs.chatMessages as any).addMessage(res.data.message)
					}).catch(() => {
						this.$nuxt.$emit('showToastMessage', {
							message: 'An error occurred while sending your message.',
							duration: 3000,
						})
					}).finally(() => {
						this.sendingMessage = false
					})
				}
			}
		},
		loadConversations() {
			if (this.selectedConversation) {
				// Skip conversation reloading when in chat
				return
			}

			this.$axios.get('/api/chat/conversations', this.lastConversationActivity ? {
				params: {
					last_activity: this.lastConversationActivity,
				},
				progress: false,
			} : {
				progress: false,
			}).then((res) => {
				if (this.lastConversationActivity) {
					res.data.conversations.forEach((updatedConversation: any) => {
						this.conversations.forEach((existingConversation, index) => {
							if (updatedConversation.id === existingConversation.id) {
								this.conversations.splice(index, 1)
							}
						})
					})

					this.conversations.unshift(...res.data.conversations)
				} else {
					this.conversations = res.data.conversations
				}

				if (res.data.last_activity) {
					this.lastConversationActivity = res.data.last_activity.date
				}

				this.updateOnlineStatus()
				this.checkForUnreadMessages()
			})
		},
		checkForUnreadMessages() {
			const unreadMessagesBefore = this.unreadMessages
			const unreadConversations: Array<any> = []
			this.unreadMessages = false

			this.conversations.forEach((conversation : any) => {
				if (conversation.unread) {
					this.unreadMessages = true
					unreadConversations.push(conversation)
				}
			})

			if (!unreadMessagesBefore && unreadConversations.length === 1) {
				this.showTooltip = true
				this.tooltipContent = unreadConversations[0].type === 'group'
					? `${this.$t('chats.newMessageIn')} ${unreadConversations[0].group.name}`
					: `${this.$t('chats.newMessageFrom')} ${unreadConversations[0].conversation_partner.name}`

				setTimeout(() => {
					this.showTooltip = false
				}, 5000)
			}
		},
		handleImage(e: any) {
			this.dragActive = false

			const selectedImage = e.target.files[0]
			const reader = new FileReader()

			reader.onload = (evt: any) => {
				this.image = evt.target.result
			}

			reader.readAsDataURL(selectedImage)
			this.imageFileExtension = e.target.files[0].name.split('.').pop().toLowerCase()
		},
		pasteFunction(pasteEvent: any, callback: any) {
			if (pasteEvent.clipboardData === false) {
				if (typeof callback === 'function') {
					callback(undefined)
				}
			}

			const { items } = pasteEvent.clipboardData

			if (items === undefined) {
				if (typeof callback === 'function') {
					callback(undefined)
				}
			}

			for (let i = 0; i < items.length; i += 1) {
				// eslint-disable-next-line no-continue
				if (!items[i].type.includes('image') || !items[i].getAsFile()) continue

				const blob = items[i].getAsFile()

				const reader = new FileReader()
				reader.onload = (e: any) => {
					this.image = e.target.result
				}

				reader.readAsDataURL(blob)
				this.imageFileExtension = blob.name.split('.').pop().toLowerCase()
			}
		},
		escapeText(text: string) {
			const map = <any> {
				'&': '&amp;',
				'<': '&lt;',
				'>': '&gt;',
				'"': '&quot;',
				'\'': '&#039;',
			}

			return text.replace(/[&<>"']/g, (m) => map[m])
		},
		lastMessagePreview(msg: any) {
			// eslint-disable-next-line max-len
			if (!msg.text.replace(/https:\/\/([a-z.]*)within\.finance\/(company|channel|profile|post|news)\/[a-zA-Z0-9_-]*/g, '').length) {
				if (msg.links && msg.links[0]) {
					switch (msg.links[0].type) {
						case 'article':
							return msg.links[0].headline

						case 'post':
							return this.$t('general.post')

						case 'company':
						case 'profile':
							return msg.links[0].name

						case 'channel':
							return msg.links[0].titles[this.$i18n.locale]
								? msg.links[0].titles[this.$i18n.locale]
								: msg.links[0].titles.first()

						default:
							return 'Link'
					}
				}
			}

			return msg.text.length ? msg.text.substring(0, 75) : this.$t('chats.unknownLastMessageContent')
		},
		updateOnlineStatus() {
			const userIds: Array<any> = []
			this.conversations.forEach((conv) => {
				if (conv.type === 'direct' && conv.conversation_partner) {
					userIds.push(conv.conversation_partner.id)
				}
			})

			if (userIds.length) {
				this.$axios.post('/api/users/online-status', {
					users: userIds,
				}, {
					progress: false,
				}).then((resp) => {
					this.conversations.forEach((conv) => {
						if (conv.type === 'direct' && conv.conversation_partner) {
							// eslint-disable-next-line no-param-reassign
							conv.conversation_partner.online = resp.data.users[conv.conversation_partner.id]
						}
					})
				})
			}
		},
		windowFocus() {
			this.now = moment()

			if (!this.connection || this.connection.readyState !== 1) {
				this.connect()
			}
		},
	},
})

import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=35afee5a&"
import script from "./Popup.vue?vue&type=script&lang=ts&"
export * from "./Popup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules_dev/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/vercel/path0/components/elements/SvgIcon.vue').default,LoadingIndicator: require('/vercel/path0/components/elements/LoadingIndicator.vue').default})





















































import Vue from 'vue'
import moment from 'moment'

import { dynamicDate, staticDate } from '@/helpers/DateHelper'
import { escapeHtml } from '~/helpers/FormatHelper'

export default Vue.extend({
	props: {
		conversation: {
			type: Object,
			required: true,
		},
		pause: {
			type: Boolean,
			default: false,
		},
		now: {
			type: moment,
			default: moment(),
		},
		connection: {
			type: undefined,
			required: true,
		},
	},
	data() {
		return {
			messages: <Array<any> | null> null,
			moreMessagesAvailable: false,
			imageSelected: <string|null> null,
			windowIsFocused: true,
		}
	},
	watch: {
		conversation() {
			this.messages = null
			this.loadMessages()
		},
	},
	mounted() {
		this.loadMessages()

		this.$axios.post(`/api/chat/group/${this.conversation.group.id}/messages/read`, {}, {
			progress: false,
		})

		window.addEventListener('focus', this.windowFocus)
		window.addEventListener('blur', this.windowBlur)
	},
	beforeDestroy() {
		window.removeEventListener('focus', this.windowFocus)
		window.removeEventListener('blur', this.windowBlur)
	},
	methods: {
		loadMessages() {
			if (!this.conversation) {
				return
			}

			this.$axios.get(`/api/chat/group/${this.conversation.id}/messages`, {
				params: {
					order: 'desc',
					offset: 0,
					limit: 20,
				},
				progress: false,
			}).then((res) => {
				const messages = res.data.messages.reverse()
				let lastSentMessageIndex: number|null = null
				let lastReadMessageIndex: number|null = null
				let lastDate: any = null

				messages.forEach((item: any, index: number) => {
					if (!lastDate || lastDate.diff(item.date) < -3600000) {
						messages[index].showDate = true
					}

					lastDate = moment(item.date)

					if (item.sender) {
						if (
							(item.sender.id && item.sender.id === this.$auth.user?.id)
							|| item.sender === this.$auth.user?.id
						) {
							lastSentMessageIndex = index

							if (item.read) {
								lastReadMessageIndex = index
							}
						}
					}
				})

				if (
					lastSentMessageIndex !== null
					&& messages[lastSentMessageIndex].sender.id === this.$auth.user?.id
					&& messages[lastSentMessageIndex].read === this.conversation.member_count
				) {
					messages[lastSentMessageIndex].showReadIndicator = true
				}

				if (lastReadMessageIndex !== null) {
					messages[lastReadMessageIndex].showReadIndicator = true
				}

				this.messages = messages || []
				this.moreMessagesAvailable = res.data.more_messages_available

				this.$nextTick(() => {
					if (this.$refs.messageContainer) {
						const mc = <any> this.$refs.messageContainer
						mc.scrollTop = mc.scrollHeight
					}
				})
			})
		},
		loadNewMessages() {
			if (!this.conversation) {
				return
			}

			if (!this.messages || this.messages.length < 1) {
				this.loadMessages()
				return
			}

			if (this.conversation.exit_date) {
				return
			}

			if (this.pause) {
				return
			}

			const mc = <any> this.$refs.messageContainer
			const isAtBottom = mc
				&& mc.scrollHeight
				&& ((mc.scrollHeight - mc.offsetHeight) - mc.scrollTop) < 20

			this.$axios.get(`/api/chat/group/${this.conversation.id}/new-messages/`, {
				params: {
					lastMessageId: this.messages[this.messages.length - 1].id,
				},
				progress: false,
			}).then((res) => {
				if (this.pause) {
					return
				}

				if (res.data.messages && res.data.messages.length) {
					if (this.messages) {
						this.messages.push(...res.data.messages)
					}

					this.$axios.post(`/api/chat/group/${this.conversation.id}/messages/read`, {}, {
						progress: false,
					})

					if (isAtBottom) {
						this.$nextTick(() => {
							mc.scrollTop = mc.scrollHeight
						})
					}
				}
			})
		},
		loadMoreMessages(amount: number) {
			const mc = <any> this.$refs.messageContainer
			const height = mc.scrollHeight

			if (!this.messages) {
				return
			}

			this.$axios.get(
				`/api/chat/group/${this.conversation.id}/previous-messages/`,
				{
					params: {
						firstMessageId: this.messages[0].id,
						limit: amount,
					},
					progress: false,
				},
			).then((res) => {
				if (res.data.messages && this.messages) {
					this.messages.unshift(...res.data.messages)
				}

				this.moreMessagesAvailable = res.data.more_messages_available

				this.$nextTick(() => {
					mc.scrollTop = mc.scrollHeight - height
				})
			})
		},
		escapeText(text: string) {
			return escapeHtml(text)
				// eslint-disable-next-line max-len
				.replace(/https:\/\/([a-z.]*)within\.finance\/(company|channel|profile|post|news)\/[a-zA-Z0-9_-]*/g, '')
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const mc = <any> this.$refs.messageContainer
				mc.scrollTop = mc.scrollHeight
			})
		},
		addMessage(message: any, alwaysScrollToBottom = false) {
			if (!this.messages) {
				this.messages = []
			}

			this.messages.push(message)

			const mc = <any> this.$refs.messageContainer
			const isAtBottom = mc
				&& mc.scrollHeight
				&& ((mc.scrollHeight - mc.offsetHeight) - mc.scrollTop) < 20

			if (alwaysScrollToBottom || isAtBottom) {
				this.scrollToBottom()

				if (this.windowIsFocused) {
					this.markAsRead()
				}
			}
		},
		imageLoaded() {
			const mc = <any> this.$refs.messageContainer

			if (mc && mc.scrollHeight && ((mc.scrollHeight - mc.offsetHeight) - mc.scrollTop) < 200) {
				this.scrollToBottom()
				setTimeout(this.scrollToBottom, 100)
			}
		},
		markAsRead() {
			if (this.connection && (this.connection as any).readyState === 1) {
				(this.connection as any).send(JSON.stringify({
					action: 'mark_as_read_group',
					group: this.conversation.id,
				}))
			}
		},
		windowFocus() {
			this.windowIsFocused = true
			this.markAsRead()
		},
		windowBlur() {
			this.windowIsFocused = false
		},
		dynamicDate,
		staticDate,
	},
})

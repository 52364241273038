var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"popup"}},[(_vm.show)?_c('div',{staticClass:"popup-wrapper-default",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () {
			if (_vm.closeOnClickOutside !== false) {
				_vm.$emit('close')
			}
		})($event)}}},[_c('div',{staticClass:"popup",class:{
				compact: _vm.compact,
				padding: _vm.padding,
			},style:({ 'max-width': _vm.maxWidth || undefined, 'padding-top': _vm.topPadding || undefined })},[_c('a',{staticClass:"close-btn",on:{"click":function () { return _vm.$emit('close'); }}},[_c('svg-icon',{attrs:{"name":"close"}})],1),(_vm.isLoading)?_c('div',{staticClass:"loading-wrapper"},[_c('loading-indicator',{attrs:{"large":""}})],1):_vm._t("default")],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
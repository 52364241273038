import moment from 'moment'

export const dynamicDate = (
	now: moment.Moment = moment(),
	dateString: string,
	locale: string,
): string => {
	const date = moment(dateString)
	const diff = date.diff(now)
	const duration = moment.duration(diff)

	if (duration.asSeconds() > 0) {
		return locale === 'de' ? 'gerade eben' : 'just now'
	} if (duration.asSeconds() > -3600) {
		return duration.locale(locale).humanize(true)
	}

	date.locale(locale)
	return date.calendar(null, {
		sameElse: 'DD.MM.YY HH:mm',
	})
}

export const staticDate = (dateString: string): string => {
	const date = moment(dateString)
	return date.format('DD.MM.YYYY HH:mm')
}

export const staticDateShort = (dateString: string): string => {
	const date = moment(dateString)
	return date.format('DD.MM.YY')
}

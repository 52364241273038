






















import Vue from 'vue'

export default Vue.extend({
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		message: {
			type: String,
			required: true,
		},
		showBtns: {
			type: Boolean,
			default: false,
		},
		buttonHandler: {
			type: Function,
			default: () => null,
		},
		hideToastHandler: {
			type: Function,
			default: () => null,
		},
		left: {
			type: Boolean,
			default: false,
		},
	},
})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules_dev/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules_dev/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/bear.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "main[data-v-10f2bb0a]{display:flex;justify-content:center;align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;margin-bottom:25px}div.error[data-v-10f2bb0a]{text-align:left;color:#fff}@media(min-width:650px){div.error[data-v-10f2bb0a]{margin-left:50%}}div.error h1.large[data-v-10f2bb0a]{font-size:7rem;font-family:inherit;font-weight:300;letter-spacing:1.5rem}div.error a[data-v-10f2bb0a]{color:inherit;text-decoration:underline}", ""]);
// Exports
module.exports = exports;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.messages)?_c('div',{ref:"messageContainer",staticClass:"messages group"},[(_vm.moreMessagesAvailable)?_c('a',{staticClass:"load-more",on:{"click":function($event){return _vm.loadMoreMessages(5)}}},[_vm._v(" ↑ "+_vm._s(_vm.$t('chat.showPreviousMessages'))+" ")]):_vm._e(),_c('transition-group',{attrs:{"name":"message","tag":"div"}},_vm._l((_vm.messages),function(message,index){return _c('message',{key:message.id,attrs:{"message":message,"is-last-message":index === _vm.messages.length - 1,"group-member-count":_vm.conversation.member_count,"now":_vm.now,"image-loaded":_vm.imageLoaded,"show-sender-image":(
				(!message.sender || message.sender.id !== _vm.$auth.user.id) && (
					!_vm.messages[index-1]
					|| (
						!message.sender
						|| !_vm.messages[index-1].sender
						|| _vm.messages[index-1].sender.id !== message.sender.id
					)
				)
			),"image-selected":function (img) { _vm.imageSelected = img; },"is-group-message":""}})}),1),(_vm.conversation.exit_date)?_c('p',{staticClass:"no-member-notice"},[_vm._v(" "+_vm._s(_vm.$t('chats.noMember'))+" ")]):_vm._e(),(_vm.messages && !_vm.messages.length)?_c('p',{staticClass:"no-messages"},[_vm._v(" "+_vm._s(_vm.$t('chats.noMessages'))+" ")]):_vm._e(),(_vm.imageSelected)?_c('ImageLightbox',{on:{"close":function () { return _vm.imageSelected = null; }}},[_c('img',{attrs:{"src":_vm.imageSelected}})]):_vm._e()],1):_c('div',{staticClass:"loading-wrapper-inline"},[_c('loading-indicator')],1)}
var staticRenderFns = []

export { render, staticRenderFns }

























































import Vue from 'vue'

export default Vue.extend({
	data() {
		return {
			feedbackText: '',
			feedbackType: 'General',
			sent: false,
			error: false,
			showPopup: false,
			isSending: false,
		}
	},
	methods: {
		send() {
			const formData = new FormData()
			const imagefile = <any> document.querySelector('#screenshot-file')

			if (imagefile && imagefile.files[0]) {
				formData.append('screenshot', imagefile.files[0])
			}

			formData.append('type', this.feedbackType)
			formData.append('message', this.feedbackText)
			formData.append('page', this.$router.currentRoute.fullPath)
			formData.append('browser_version', navigator.appVersion)
			formData.append('browser_name', navigator.appName)
			formData.append('browser_agent', navigator.userAgent)

			this.isSending = true
			this.$axios.post('/api/feedback', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then((resp) => {
				if (resp.data.status === 'ok') {
					this.sent = true
					this.feedbackText = ''
					this.feedbackType = 'general'
				} else {
					this.error = true
				}
			}).catch(() => {
				this.error = true
			}).finally(() => {
				this.isSending = false
			})
		},
	},
})

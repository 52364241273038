
























import Vue from 'vue'

export default Vue.extend({
	props: {
		accepted: {
			type: Function,
			required: true,
		},
	},
})

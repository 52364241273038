





























































import Vue from 'vue'

export default Vue.extend({
	name: 'Login',
	props: {
		expired: {
			type: Boolean,
			default: false,
		},
		onLoginSuccess: {
			type: Function,
			default: () => null,
		},
	},
	data() {
		return {
			showSignInWithApple: false,
			show: false,
			isLoading: false,
			email: '',
			password: '',
			showResendBtn: false,
			hideToastTimeout: <any> null,
		}
	},
	head(this: any) {
		const i18nSeo: any = this.$nuxtI18nSeo()
		i18nSeo.script = [...i18nSeo.script || [], {
			src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
		}]
		return i18nSeo
	},
	mounted() {
		this.$nextTick(() => {
			this.show = true
		})

		if (this.expired && localStorage.getItem('email')) {
			this.email = localStorage.getItem('email') || ''
		}

		setTimeout(() => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-undef
			AppleID.auth.init({
				clientId: 'finance.within.web',
				scope: 'name email',
				redirectURI: 'https://api.within.finance/auth-redirect',
				state: (Math.random() + 1).toString(36).substring(2),
				// nonce: '[NONCE]',
				usePopup: false,
			})
		}, 500)
	},
	methods: {
		async login() {
			if (this.isLoading) {
				return
			}

			this.isLoading = true

			try {
				await this.$auth.loginWith('local', {
					data: {
						email: this.email,
						password: this.password,
					},
				})

				if (!this.$auth.user) {
					return
				}

				if (this.$auth.user.primary_language) {
					this.$i18n.setLocale(this.$auth.user.primary_language as string || 'en')
				}

				localStorage.setItem('email', this.$auth.user.email as string)

				if (this.expired) {
					this.onLoginSuccess()
					return
				}

				if (localStorage.getItem('login_redirect')) {
					this.$router.push(localStorage.getItem('login_redirect') || '/feed')
					localStorage.removeItem('login_redirect')
				} else {
					this.$router.push('/feed')
				}
			} catch (err: any) {
				this.isLoading = false

				if (err.response && err.response.data.status === 'email_unverified') {
					this.$nuxt.$emit('showToastMessage', {
						message: this.$t('login.unverified').toString(),
						duration: 4000,
					})

					this.showResendBtn = true
				} else {
					this.$nuxt.$emit('showToastMessage', {
						message: this.$t('login.invalidCredentials').toString(),
						duration: 4000,
					})
				}
			}
		},
		forgot() {
			if (this.email.length) {
				this.isLoading = true

				this.$axios.post('/api/auth/password-reset/request', {
					email: this.email,
				}).then((resp) => {
					if (resp.data.status === 'ok') {
						this.$nuxt.$emit('showToastMessage', {
							// eslint-disable-next-line max-len
							message: 'Bitte überprüfen Sie Ihr E-Mail-Postfach. Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen zum Zurücksetzen des Passwortes.',
							duration: 6000,
						})
					} else {
						this.$nuxt.$emit('showToastMessage', {
							message: 'Leider wurde kein Account zu dieser E-Mail-Adresse gefunden.',
							duration: 4000,
						})
					}
				}).catch(() => {
					this.$nuxt.$emit('showToastMessage', {
						message: 'Leider wurde kein Account zu dieser E-Mail-Adresse gefunden.',
						duration: 4000,
					})
				})
			} else {
				this.$nuxt.$emit('showToastMessage', {
					message: 'Bitte geben Sie zunächst Ihre E-Mail-Adresse ein.',
					duration: 4000,
				})
			}
		},
		resendActivationEmail() {
			this.isLoading = true

			this.$axios.post('/api/auth/resend-activation', {
				email: this.email,
			}).then((resp) => {
				if (resp.data.status === 'ok') {
					this.showResendBtn = false
					this.$nuxt.$emit('showToastMessage', {
						message: 'Sie erhalten in Kürze eine erneute Bestätigungs-Email, die für 48 Stunden gültig ist.',
						duration: 6000,
					})
				} else if (resp.data.status === 'limit_exceeded') {
					this.$nuxt.$emit('showToastMessage', {
						// eslint-disable-next-line max-len
						message: 'Sie haben bereits mehrere E-Mails angefordert. Bitte überprüfen Sie Ihr E-Mail-Postfach oder kontaktieren Sie uns bei Problemen.',
						duration: 6000,
					})
				}
			}).catch(() => {
				this.$nuxt.$emit('showToastMessage', {
					message: 'Leider wurde kein Account zu dieser E-Mail-Adresse gefunden.',
					duration: 6000,
				})
			}).finally(() => {
				this.isLoading = false
			})
		},
	},
})

(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
    typeof define === 'function' && define.amd ? define(['exports'], factory) :
    (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.VueInlineSvg = {}));
}(this, (function (exports) { 'use strict';

    /** @type Object{string: Promise<Element>} */
    var cache = {};
    /**
     * Remove false attrs
     * @param {Object} attrs
     */

    function filterAttrs(attrs) {
      return Object.keys(attrs).reduce(function (result, key) {
        if (attrs[key] !== false && attrs[key] !== null && attrs[key] !== undefined) {
          result[key] = attrs[key];
        }

        return result;
      }, {});
    }

    var InlineSvgComponent = {
      // name: 'inline-svg',
      inheritAttrs: false,
      render: function render(createElement) {
        if (!this.svgElSource) {
          return null;
        }

        return createElement('svg', {
          on: this.$listeners,
          attrs: Object.assign(this.getSvgAttrs(this.svgElSource), filterAttrs(this.$attrs)),
          domProps: {
            innerHTML: this.getSvgContent(this.svgElSource)
          }
        });
      },
      props: {
        src: {
          type: String,
          required: true
        },
        title: {
          type: String
        },
        transformSource: {
          type: Function,
          "default": function _default(svg) {
            return svg;
          }
        },
        keepDuringLoading: {
          type: Boolean,
          "default": true
        }
      },
      data: function data() {
        return {
          /** @type SVGElement */
          svgElSource: null
        };
      },
      watch: {
        src: function src(newValue) {
          // re-generate cached svg (`svgElSource`)
          this.getSource(newValue);
        }
      },
      mounted: function mounted() {
        // generate `svgElSource`
        this.getSource(this.src);
      },
      methods: {
        getSvgAttrs: function getSvgAttrs(svgEl) {
          // copy attrs
          var svgAttrs = {};
          var attrs = svgEl.attributes;

          if (!attrs) {
            return svgAttrs;
          }

          for (var i = attrs.length - 1; i >= 0; i--) {
            svgAttrs[attrs[i].name] = attrs[i].value;
          }

          return svgAttrs;
        },
        getSvgContent: function getSvgContent(svgEl) {
          svgEl = svgEl.cloneNode(true);
          svgEl = this.transformSource(svgEl);

          if (this.title) {
            setTitle(svgEl, this.title);
          } // copy inner html


          return svgEl.innerHTML;
        },

        /**
         * Get svgElSource
         * @param {string} src
         */
        getSource: function getSource(src) {
          var _this = this;

          // fill cache by src with promise
          if (!cache[src]) {
            // download
            cache[src] = this.download(src);
          } // notify svg is unloaded


          if (this.svgElSource && cache[src].isPending() && !this.keepDuringLoading) {
            this.svgElSource = null;
            this.$emit('unloaded');
          } // inline svg when cached promise resolves


          cache[src].then(function (svg) {
            _this.svgElSource = svg; // wait to render

            _this.$nextTick(function () {
              // notify
              _this.$emit('loaded', _this.$el);
            });
          })["catch"](function (err) {
            // notify svg is unloaded
            if (_this.svgElSource) {
              _this.svgElSource = null;

              _this.$emit('unloaded');
            } // remove cached rejected promise so next image can try load again


            delete cache[src];

            _this.$emit('error', err);
          });
        },

        /**
         * Get the contents of the SVG
         * @param {string} url
         * @returns {Promise<Element>}
         */
        download: function download(url) {
          return makePromiseState(new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();
            request.open('GET', url, true);

            request.onload = function () {
              if (request.status >= 200 && request.status < 400) {
                try {
                  // Setup a parser to convert the response to text/xml in order for it to be manipulated and changed
                  var parser = new DOMParser();
                  var result = parser.parseFromString(request.responseText, 'text/xml');
                  var svgEl = result.getElementsByTagName('svg')[0];

                  if (svgEl) {
                    // svgEl = this.transformSource(svgEl);
                    resolve(svgEl);
                  } else {
                    reject(new Error('Loaded file is not valid SVG"'));
                  }
                } catch (e) {
                  reject(e);
                }
              } else {
                reject(new Error('Error loading SVG'));
              }
            };

            request.onerror = reject;
            request.send();
          }));
        }
      }
    };
    /**
     * Create or edit the <title> element of a SVG
     * @param {SVGElement} svg
     * @param {string} title
     */

    function setTitle(svg, title) {
      var titleTags = svg.getElementsByTagName('title');

      if (titleTags.length) {
        // overwrite existing title
        titleTags[0].textContent = title;
      } else {
        // create a title element if one doesn't already exist
        var titleEl = document.createElementNS('http://www.w3.org/2000/svg', 'title');
        titleEl.textContent = title;
        svg.appendChild(titleEl);
      }
    }
    /**
     * @typedef {Promise} PromiseWithState
     * @property {Function<boolean>} isPending
     */

    /**
     * This function allow you to modify a JS Promise by adding some status properties.
     * @param {Promise|PromiseWithState} promise
     * @return {PromiseWithState}
     */


    function makePromiseState(promise) {
      // Don't modify any promise that has been already modified.
      if (promise.isPending) return promise; // Set initial state

      var isPending = true; // Observe the promise, saving the fulfillment in a closure scope.

      var result = promise.then(function (v) {
        isPending = false;
        return v;
      }, function (e) {
        isPending = false;
        throw e;
      });

      result.isPending = function getIsPending() {
        return isPending;
      };

      return result;
    }

    var InlineSvgPlugin = {
      install: function install(Vue) {
        Vue.component('inline-svg', InlineSvgComponent);
      }
    };

    exports.InlineSvgComponent = InlineSvgComponent;
    exports.InlineSvgPlugin = InlineSvgPlugin;
    exports.default = InlineSvgComponent;

    Object.defineProperty(exports, '__esModule', { value: true });

})));

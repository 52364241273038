
































import Vue from 'vue'

export default Vue.extend({
	name: 'Error',
	props: {
		error: {
			type: undefined,
			required: true,
		},
	},
})

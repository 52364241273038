var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.message.type === 'event')?_c('div',{staticClass:"event"},[_c('p',[_vm._v(_vm._s(_vm.eventText))])]):_c('div',{staticClass:"message-wrapper",class:{ own: _vm.isOwnMessage }},[(_vm.message.date && _vm.message.showDate)?_c('label',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dynamicDate(_vm.now, _vm.message.date, _vm.$i18n.locale))+" ")]):_vm._e(),(_vm.isGroupMessage && _vm.showSenderImage)?_c('label',{staticClass:"sender"},[_c('img',{staticClass:"user-profile-picture",attrs:{"src":_vm.message.sender && _vm.message.sender.profile_picture
				? _vm.message.sender.profile_picture
				: require('@/assets/user-circle.svg')}}),_c('span',[_vm._v(_vm._s(_vm.message.sender ? _vm.message.sender.name : 'gelöschter Benutzer'))])]):_vm._e(),(_vm.message.text.length && _vm.escapeText(_vm.message.text).length)?_c('div',{directives:[{name:"linkified",rawName:"v-linkified:options",value:({ nl2br: true, target: { url: '_blank' } }),expression:"{ nl2br: true, target: { url: '_blank' } }",arg:"options"}],staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.escapeText(_vm.message.text))}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]):_vm._e(),(_vm.message.images && _vm.message.images.length)?_c('div',{staticClass:"image-previews"},_vm._l((_vm.message.images),function(image){return _c('a',{key:image.id,attrs:{"href":image.url,"target":"_blank"},on:{"click":function (e) {
				e.preventDefault();
				_vm.imageSelected(image.url);
			}}},[_c('img',{attrs:{"src":image.preview_url},on:{"load":function () {
					if (_vm.imageLoaded !== undefined) {
						_vm.imageLoaded();
					}
				}}})])}),0):_vm._e(),(_vm.message.links && _vm.message.links.length)?_c('div',{staticClass:"links"},_vm._l((_vm.message.links),function(link,linkIndex){return _c('nuxt-link',{key:'link-' + linkIndex,staticClass:"within-link",class:link.type,style:({
				'background-image': link.preview_image_url ? 'url(\'' + link.preview_image_url + '\')' : ''
			}),attrs:{"to":link.path}},[(link.type === 'company')?_c('div',[_c('label',[_vm._v(_vm._s(link.name))]),_c('span',{staticClass:"sub"},[_vm._v(_vm._s(_vm.$t('company.keyData.company')))])]):(link.type === 'channel')?_c('div',[_c('label',[_vm._v(_vm._s(link.titles[_vm.$i18n.locale] ? link.titles[_vm.$i18n.locale] : link.titles.first()))]),_c('span',{staticClass:"sub"},[_vm._v("Channel")])]):_vm._e(),(link.type === 'profile')?_c('div',[_c('div',[_c('img',{staticClass:"user-profile-picture",attrs:{"src":link.profile_picture ? link.profile_picture : require('@/assets/user-circle.svg')}}),_c('label',[_vm._v(_vm._s(link.name)),_c('br'),_c('span',{staticClass:"sub"},[_vm._v(_vm._s(_vm.$t('onboarding.steps.profile')))])])])]):_vm._e(),(link.type === 'post')?_c('div',[_c('label',[_c('b',[_vm._v(_vm._s(_vm.$t('chats.links.postFrom'))+" "+_vm._s(link.author)+":")]),_vm._v(" "+_vm._s(link.text)+" ")])]):_vm._e(),(link.type === 'article')?_c('div',[_c('label',[_vm._v(_vm._s(link.headline))]),_c('span',{staticClass:"sub"},[_vm._v("News")])]):_vm._e()])}),1):_vm._e(),_c('label',{staticClass:"date-sub"},[_vm._v(" "+_vm._s(_vm.dynamicDate(_vm.now, _vm.message.date, _vm.$i18n.locale))+" ")]),((
			_vm.isGroupMessage
			&& _vm.message.read === _vm.groupMemberCount
			&& _vm.message.showReadIndicator
			&& _vm.message.sender
			&& _vm.message.sender.id === _vm.$auth.user.id)
		|| (
			!_vm.isGroupMessage
			&& _vm.message.read
			&& _vm.message.showReadIndicator
			&& _vm.message.sender === _vm.$auth.user.id
		))?_c('label',{staticClass:"read",staticStyle:{"color":"#29b6f6"}},[_c('svg-icon',{attrs:{"name":"message-read","size":15}}),_vm._v(" "+_vm._s(!_vm.isGroupMessage ? _vm.dynamicDate(_vm.now, _vm.message.read, _vm.$i18n.locale) : '')+" ")],1):((
			_vm.isGroupMessage
			&& _vm.isLastMessage
			&& _vm.message.sender
			&& _vm.message.sender.id === _vm.$auth.user.id
		)
		|| (
			!_vm.message.read
			&& _vm.isLastMessage
			&& _vm.message.sender === _vm.$auth.user.id
		))?_c('label',{staticClass:"read"},[_c('svg-icon',{attrs:{"name":"message-sent","size":15}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
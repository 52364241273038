


































































































































































































import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
	props: {
		hide: {
			type: Function,
			default: () => null,
		},
		setStatus: {
			type: Function,
			default: () => null,
		},
	},
	data() {
		return {
			notifications: <any> [],
			showReadNotifications: false,
			moreNotificationsAvailable: false,
			now: <any> null,
			notificationUpdateInterval: <any> null,
			updateNowInterval: <any> null,
		}
	},
	mounted() {
		if (this.$auth.loggedIn) {
			this.loadNotifications()
			this.updateNow()
			this.notificationUpdateInterval = setInterval(this.updateNotifications, 30000)
			this.updateNowInterval = setInterval(this.updateNow, 60000)
		}

		this.$auth.$storage.watchState('loggedIn', () => {
			if (this.$auth.loggedIn) {
				this.loadNotifications()
			} else {
				clearTimeout(this.notificationUpdateInterval)
				clearInterval(this.updateNowInterval)
			}
		})

		window.addEventListener('focus', this.windowFocus)
		window.addEventListener('blur', this.windowBlur)
	},
	beforeDestroy() {
		clearInterval(this.notificationUpdateInterval)
		clearInterval(this.updateNowInterval)
	},
	methods: {
		loadMoreNotifications() {
			this.showReadNotifications = true

			this.$axios.get('/api/user/notifications', {
				params: {
					last_notification_id: this.notifications[this.notifications.length - 1]
						? this.notifications[this.notifications.length - 1].id
						: null,
					limit: 5,
				},
				progress: false,
			}).then((resp) => {
				this.notifications.push(...resp.data.notifications)
				this.moreNotificationsAvailable = resp.data.more_available
			})
		},
		markNotificationAsRead(notification: any) {
			this.$axios.post(`/api/user/notification/${notification.id}/read`).then((resp) => {
				if (resp.data.status === 'ok') {
					// eslint-disable-next-line no-param-reassign
					notification.read = true
					this.updateNotificationStatus()
				}
			})
		},
		updateNotificationStatus() {
			this.setStatus(this.notifications.filter((i: any) => i.read === null).length)
		},
		markAllNotificationsAsRead() {
			this.$axios.post('/api/user/notifications/read').then((resp) => {
				if (resp.data.status === 'ok') {
					this.notifications.forEach((notification: any) => {
						// eslint-disable-next-line no-param-reassign
						notification.read = true
						this.setStatus(0)
					})
				}
			})
		},
		deleteNotification(notification: any, index: number) {
			this.$axios.delete(`/api/user/notification/${notification.id}`).then((resp) => {
				if (resp.data.status === 'ok') {
					if (index === 0) {
						const { notifications } = this
						notifications.shift()
						this.notifications = notifications
					} else {
						this.notifications.splice(index, 1)
					}

					this.updateNotificationStatus()
				}
			})
		},
		updateNotifications() {
			if (!this.$auth.loggedIn) {
				clearInterval(this.notificationUpdateInterval)
				clearInterval(this.updateNowInterval)
				this.notifications = []
				return
			}

			this.$axios.get('/api/user/notifications/new', {
				params: {
					last_notification_id: this.notifications[0] ? this.notifications[0].id : null,
					limit: 10,
				},
				progress: false,
			}).then((resp) => {
				this.notifications.unshift(...resp.data.notifications)
				this.updateNotificationStatus()
			})
		},
		calculateDateDifference(now: any, dateString : string) {
			const date = moment(dateString)
			const diff = date.diff(now)
			const duration = moment.duration(diff)
			return duration.locale(this.$i18n.locale).humanize(true)
		},
		updateNow() {
			this.now = moment()
		},
		openNotification(notification: any) {
			switch (notification.type) {
				case 'new_comment':
				case 'new_comment_reply':
				case 'new_comment_quote':
					this.markNotificationAsRead(notification)
					this.hide()
					this.showReadNotifications = false

					if (notification.reference.article) {
						this.$router.push(`/news/${notification.reference.article.id}`)
					} else {
						this.$router.push(`/post/${notification.reference.post.id}`)
					}
					break

				case 'new_subscriber':
					this.markNotificationAsRead(notification)
					this.hide()
					this.showReadNotifications = false
					this.$router.push(`/profile/${notification.reference.id}`)
					break

				case 'conversation_invitation':
				case 'group_invitation':
					this.markNotificationAsRead(notification)
					this.hide()
					this.showReadNotifications = false
					this.$router.push('/chats/invitations')
					break

				case 'group_promotion':
					this.hide()
					this.showReadNotifications = false
					this.$router.push(`/chats/${notification.reference.id}`)
					break

				default:
					break
			}
		},
		loadNotifications() {
			this.$axios.get('/api/user/notifications', {
				params: {
					limit: 10,
				},
				progress: false,
			}).then((resp) => {
				this.notifications = resp.data.notifications
				this.moreNotificationsAvailable = resp.data.more_available
				this.updateNotificationStatus()
			})
		},
		windowFocus() {
			clearInterval(this.notificationUpdateInterval)
			clearInterval(this.updateNowInterval)
			this.notificationUpdateInterval = setInterval(this.updateNotifications, 30000)
			this.updateNowInterval = setInterval(this.updateNow, 60000)
			this.updateNow()
		},
		windowBlur() {
			clearInterval(this.notificationUpdateInterval)
			clearInterval(this.updateNowInterval)
		},
	},
})

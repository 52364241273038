import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b0ba114 = () => interopDefault(import('../pages/apple-auth/index.vue' /* webpackChunkName: "pages/apple-auth/index" */))
const _95255c2e = () => interopDefault(import('../pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _18ed07f8 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _48d1dcf3 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _fc264d92 = () => interopDefault(import('../pages/feed/index.vue' /* webpackChunkName: "pages/feed/index" */))
const _2c376cbe = () => interopDefault(import('../pages/keep-account/index.vue' /* webpackChunkName: "pages/keep-account/index" */))
const _d2978754 = () => interopDefault(import('../pages/legal/index.vue' /* webpackChunkName: "pages/legal/index" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _62738744 = () => interopDefault(import('../pages/plans.vue' /* webpackChunkName: "pages/plans" */))
const _1aa1d23a = () => interopDefault(import('../pages/plans/upgraded.vue' /* webpackChunkName: "pages/plans/upgraded" */))
const _6a1cbbbc = () => interopDefault(import('../pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _43def1b6 = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _4ec34822 = () => interopDefault(import('../pages/portfolio/manage.vue' /* webpackChunkName: "pages/portfolio/manage" */))
const _d468517a = () => interopDefault(import('../pages/portfolio/performance.vue' /* webpackChunkName: "pages/portfolio/performance" */))
const _b2c89468 = () => interopDefault(import('../pages/portfolio/risk.vue' /* webpackChunkName: "pages/portfolio/risk" */))
const _1553c436 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _0a170ec6 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _0f551892 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _4bf20d6c = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _13c579a4 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _752c8d16 = () => interopDefault(import('../pages/companies/search/index.vue' /* webpackChunkName: "pages/companies/search/index" */))
const _43444c32 = () => interopDefault(import('../pages/companies/trending/index.vue' /* webpackChunkName: "pages/companies/trending/index" */))
const _00aa70fe = () => interopDefault(import('../pages/feed/news/index.vue' /* webpackChunkName: "pages/feed/news/index" */))
const _1f446420 = () => interopDefault(import('../pages/activate/_token.vue' /* webpackChunkName: "pages/activate/_token" */))
const _8921f7f0 = () => interopDefault(import('../pages/channel/_id.vue' /* webpackChunkName: "pages/channel/_id" */))
const _ffcb36ea = () => interopDefault(import('../pages/channel/_id/index.vue' /* webpackChunkName: "pages/channel/_id/index" */))
const _74214fa0 = () => interopDefault(import('../pages/chats/_id.vue' /* webpackChunkName: "pages/chats/_id" */))
const _970eacfc = () => interopDefault(import('../pages/company/_id.vue' /* webpackChunkName: "pages/company/_id" */))
const _684c8685 = () => interopDefault(import('../pages/company/_id/index.vue' /* webpackChunkName: "pages/company/_id/index" */))
const _19fc413c = () => interopDefault(import('../pages/company/_id/analysis/index.vue' /* webpackChunkName: "pages/company/_id/analysis/index" */))
const _742487f8 = () => interopDefault(import('../pages/company/_id/details/index.vue' /* webpackChunkName: "pages/company/_id/details/index" */))
const _640836ac = () => interopDefault(import('../pages/company/_id/financials/index.vue' /* webpackChunkName: "pages/company/_id/financials/index" */))
const _027a0f21 = () => interopDefault(import('../pages/company/_id/network.vue' /* webpackChunkName: "pages/company/_id/network" */))
const _0fad8a1a = () => interopDefault(import('../pages/company/_id/news/index.vue' /* webpackChunkName: "pages/company/_id/news/index" */))
const _4cbfc432 = () => interopDefault(import('../pages/company/_id/publications/index.vue' /* webpackChunkName: "pages/company/_id/publications/index" */))
const _92bfddb6 = () => interopDefault(import('../pages/company/_id/share/charts/index.vue' /* webpackChunkName: "pages/company/_id/share/charts/index" */))
const _be336b80 = () => interopDefault(import('../pages/forgot/_token.vue' /* webpackChunkName: "pages/forgot/_token" */))
const _47e001ec = () => interopDefault(import('../pages/invitation/_token.vue' /* webpackChunkName: "pages/invitation/_token" */))
const _15375d94 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _1aac1564 = () => interopDefault(import('../pages/opt-in/_token.vue' /* webpackChunkName: "pages/opt-in/_token" */))
const _8eaf633e = () => interopDefault(import('../pages/post/_id.vue' /* webpackChunkName: "pages/post/_id" */))
const _b8f3eaa4 = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _b4143ed0 = () => interopDefault(import('../pages/ref/_id.vue' /* webpackChunkName: "pages/ref/_id" */))
const _012ace51 = () => interopDefault(import('../pages/restore-account/_token.vue' /* webpackChunkName: "pages/restore-account/_token" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apple-auth",
    component: _4b0ba114,
    name: "apple-auth"
  }, {
    path: "/channels",
    component: _95255c2e,
    name: "channels"
  }, {
    path: "/companies",
    component: _18ed07f8,
    name: "companies"
  }, {
    path: "/faq",
    component: _48d1dcf3,
    name: "faq"
  }, {
    path: "/feed",
    component: _fc264d92,
    name: "feed"
  }, {
    path: "/keep-account",
    component: _2c376cbe,
    name: "keep-account"
  }, {
    path: "/legal",
    component: _d2978754,
    name: "legal"
  }, {
    path: "/login",
    component: _5e96a5f4,
    name: "login"
  }, {
    path: "/plans",
    component: _62738744,
    name: "plans",
    children: [{
      path: "upgraded",
      component: _1aa1d23a,
      name: "plans-upgraded"
    }]
  }, {
    path: "/portfolio",
    component: _6a1cbbbc,
    children: [{
      path: "",
      component: _43def1b6,
      name: "portfolio"
    }, {
      path: "manage",
      component: _4ec34822,
      name: "portfolio-manage"
    }, {
      path: "performance",
      component: _d468517a,
      name: "portfolio-performance"
    }, {
      path: "risk",
      component: _b2c89468,
      name: "portfolio-risk"
    }]
  }, {
    path: "/privacy",
    component: _1553c436,
    name: "privacy"
  }, {
    path: "/profile",
    component: _0a170ec6,
    name: "profile"
  }, {
    path: "/registration",
    component: _0f551892,
    name: "registration"
  }, {
    path: "/support",
    component: _4bf20d6c,
    name: "support"
  }, {
    path: "/terms",
    component: _13c579a4,
    name: "terms"
  }, {
    path: "/companies/search",
    component: _752c8d16,
    name: "companies-search"
  }, {
    path: "/companies/trending",
    component: _43444c32,
    name: "companies-trending"
  }, {
    path: "/feed/news",
    component: _00aa70fe,
    name: "feed-news"
  }, {
    path: "/activate/:token?",
    component: _1f446420,
    name: "activate-token"
  }, {
    path: "/channel/:id?",
    component: _8921f7f0,
    children: [{
      path: "",
      component: _ffcb36ea,
      name: "channel-id"
    }]
  }, {
    path: "/chats/:id?",
    component: _74214fa0,
    name: "chats-id"
  }, {
    path: "/company/:id?",
    component: _970eacfc,
    children: [{
      path: "",
      component: _684c8685,
      name: "company-id"
    }, {
      path: "analysis",
      component: _19fc413c,
      name: "company-id-analysis"
    }, {
      path: "details",
      component: _742487f8,
      name: "company-id-details"
    }, {
      path: "financials",
      component: _640836ac,
      name: "company-id-financials"
    }, {
      path: "network",
      component: _027a0f21,
      name: "company-id-network"
    }, {
      path: "news",
      component: _0fad8a1a,
      name: "company-id-news"
    }, {
      path: "publications",
      component: _4cbfc432,
      name: "company-id-publications"
    }, {
      path: "share/charts",
      component: _92bfddb6,
      name: "company-id-share-charts"
    }]
  }, {
    path: "/forgot/:token?",
    component: _be336b80,
    name: "forgot-token"
  }, {
    path: "/invitation/:token?",
    component: _47e001ec,
    name: "invitation-token"
  }, {
    path: "/news/:id?",
    component: _15375d94,
    name: "news-id"
  }, {
    path: "/opt-in/:token?",
    component: _1aac1564,
    name: "opt-in-token"
  }, {
    path: "/post/:id?",
    component: _8eaf633e,
    name: "post-id"
  }, {
    path: "/profile/:id",
    component: _b8f3eaa4,
    name: "profile-id"
  }, {
    path: "/ref/:id?",
    component: _b4143ed0,
    name: "ref-id"
  }, {
    path: "/restore-account/:token?",
    component: _012ace51,
    name: "restore-account-token"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

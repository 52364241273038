// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const eventText = (context: any, message: any): string | undefined => {
	if (message.type === 'event') {
		switch (message.event_type) {
			case 'group_join_invitation':
			case 'group_join_link':
				return context.$t(
					'chat.group-events.group-join-invitation',
					{
						user: message.event_data.user,
					},
				).toString()

			case 'group_create':
				return context.$t(
					'chat.group-events.group-create',
					{
						user: message.event_data.user,
					},
				).toString()

			case 'group_leave':
				return context.$t(
					'chat.group-events.group-leave',
					{
						user: message.event_data.user,
					},
				).toString()

			case 'group_name_update':
				return context.$t(
					'chat.group-events.group-name-update',
					{
						from: message.event_data.name_from,
						to: message.event_data.name_to,
						user: message.event_data.user,
					},
				).toString()

			case 'group_image_update':
				return context.$t(
					'chat.group-events.group-image-update',
					{
						user: message.event_data.user,
					},
				).toString()

			default:
				break
		}
	}

	return undefined
}

export default eventText

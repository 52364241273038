







































































































































import Vue from 'vue'
import { dynamicDate, staticDate } from '@/helpers/DateHelper'
import { escapeHtml } from '~/helpers/FormatHelper'
import eventText from '~/helpers/GroupEventHelper'

export default Vue.extend({
	props: {
		message: {
			type: Object,
			required: true,
		},
		isLastMessage: {
			type: Boolean,
			default: false,
		},
		isGroupMessage: {
			type: Boolean,
			default: false,
		},
		groupMemberCount: {
			type: Number,
			default: null,
			required: false,
		},
		now: {
			type: Object,
			default: null,
		},
		imageLoaded: {
			type: Function,
			required: false,
			default: () => null,
		},
		showSenderImage: {
			type: Boolean,
			default: false,
		},
		imageSelected: {
			type: Function,
			default: () => null,
		},
	},
	computed: {
		isOwnMessage(): boolean {
			const ownGroupMessage = this.isGroupMessage
				&& this.message.sender
				&& this.message.sender.id === this.$auth.user?.id
			const ownDirectMessage = !this.isGroupMessage && this.message.sender === this.$auth.user?.id

			return ownGroupMessage || ownDirectMessage
		},
		eventText(): string|undefined {
			if (this.message.type === 'event') {
				return eventText(this, this.message)
			}

			return undefined
		},
	},
	methods: {
		dynamicDate,
		staticDate,
		escapeText(text: string) {
			return escapeHtml(text)
				// eslint-disable-next-line max-len
				.replace(/https:\/\/([a-z.]*)within\.finance\/(company|channel|profile|post|news)\/[a-zA-Z0-9_-]*/g, '')
		},
	},
})

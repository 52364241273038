












































import Vue from 'vue'

export default Vue.extend({
	data() {
		return {
			// loadingFinished: true,
			// hideLoading: true,
			askForCookies: false,
			toastCallback: null as any,
			isConfirmationToast: false,
			toastMessage: '',
			showToast: false,
			hideToastTimeout: null as any,
			wasLoggedIn: false,
			showReSignInPopup: false,
			explanationData: null as { title: string, text: string }|null,
		}
	},
	head(this: any) {
		const i18nSeo: any = this.$nuxtI18nSeo()

		i18nSeo.meta.push({
			hid: 'description',
			name: 'description',
			content: this.$t('meta.description').toString(),
		})

		i18nSeo.script = [
			{
				src: 'https://analytics.tinyapps.de/js/plausible.js',
				async: true,
				defer: true,
				'data-domain': 'within.finance',
			},
			// { src: 'https://www.googletagmanager.com/gtag/js?id=AW-482552220', async: true },
		]

		i18nSeo.title = 'WithIn Finance'
		return i18nSeo
	},
	computed: {
		showCookiePopup(): boolean {
			return this.askForCookies
				&& !['legal', 'terms', 'privacy'].includes(this.$nuxt.$route.name || '')
		},
	},
	mounted() {
		// setTimeout(() => {
		// 	this.loadingFinished = true

		// 	setTimeout(() => {
		// 		this.hideLoading = true
		// 	}, 500)
		// }, 500)

		if (!localStorage.getItem('cookies')) {
			this.askForCookies = true
		}

		if (this.$auth.loggedIn) {
			this.wasLoggedIn = true
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.$authHub.$on('showReSignInPopup', () => {
			this.showReSignInPopup = true
		})

		this.$nuxt.$on('disableScroll', () => {
			const { scrollTop } = document.documentElement
			document.documentElement.classList.add('disabled-scrolling')
			document.documentElement.style.top = `-${scrollTop}px`
		})

		this.$nuxt.$on('restoreScroll', () => {
			const { top } = document.documentElement.style
			document.documentElement.classList.remove('disabled-scrolling')
			document.documentElement.style.top = '0'
			document.documentElement.scrollTop = -parseInt(top, 10)
		})

		this.$nuxt.$on('showToastMessage', (config: {
			message: string,
			duration?: number|null,
			callbackMethod?: (confirmed: boolean) => void
		}) => {
			clearTimeout(this.hideToastTimeout)

			if (config.callbackMethod) {
				this.toastCallback = config.callbackMethod
				this.isConfirmationToast = true
			} else {
				this.isConfirmationToast = false
			}

			this.toastMessage = config.message
			this.showToast = true

			if (config.duration !== null && config.duration !== undefined) {
				this.hideToastTimeout = setTimeout(this.hideToast, config.duration)
			}
		})

		this.$nuxt.$on('showExplanation', (data: { title: string, text: string }) => {
			this.explanationData = data
		})
	},
	methods: {
		acceptCookies() {
			localStorage.setItem('cookies', '1')
			this.askForCookies = false
		},
		hideToast() {
			this.isConfirmationToast = false
			this.showToast = false
			this.toastCallback = () => null
		},
		reload() {
			window.location.reload()
		},
	},
})
/* eslint-disable max-len */

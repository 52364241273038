

















import Vue from 'vue'

export default Vue.extend({
	data() {
		return {
			// loadingFinished: false,
			// hideLoading: false,
			explanationData: null as { title: string, text: string }|null,
		}
	},
	head(this: any) {
		const i18nSeo: any = this.$nuxtI18nSeo()

		i18nSeo.meta.push({
			hid: 'description',
			name: 'description',
			content: this.$t('meta.description').toString(),
		})

		i18nSeo.script = [
			{
				src: 'https://analytics.tinyapps.de/js/plausible.js',
				async: true,
				defer: true,
				'data-domain': 'within.finance',
			},
			// { src: 'https://www.googletagmanager.com/gtag/js?id=AW-482552220', async: true },
			{ src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js' },
		]

		i18nSeo.title = 'WithIn Finance'
		return i18nSeo
	},
	mounted() {
		// if (process.browser) {
		// 	setTimeout(() => {
		// 		this.loadingFinished = true

		// 		setTimeout(() => {
		// 			this.hideLoading = true
		// 		}, 500)
		// 	}, 500)
		// }

		this.$nuxt.$on('showExplanation', (data: { title: string, text: string }) => {
			this.explanationData = data
		})
	},
})
/* eslint-disable max-len */

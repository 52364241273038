import Vue from 'vue'

const components = {
  Login: () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/login" */).then(c => c.default || c),
  Chat: () => import('../../components/chat/Chat.vue' /* webpackChunkName: "components/chat" */).then(c => c.default || c),
  ChatMessages: () => import('../../components/chat/ChatMessages.vue' /* webpackChunkName: "components/chat-messages" */).then(c => c.default || c),
  ChatSharePopup: () => import('../../components/chat/ChatSharePopup.vue' /* webpackChunkName: "components/chat-share-popup" */).then(c => c.default || c),
  GroupChatMessages: () => import('../../components/chat/GroupChatMessages.vue' /* webpackChunkName: "components/group-chat-messages" */).then(c => c.default || c),
  Message: () => import('../../components/chat/Message.vue' /* webpackChunkName: "components/message" */).then(c => c.default || c),
  Accordion: () => import('../../components/elements/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => c.default || c),
  Dropdown: () => import('../../components/elements/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => c.default || c),
  ExplanationTooltip: () => import('../../components/elements/ExplanationTooltip.vue' /* webpackChunkName: "components/explanation-tooltip" */).then(c => c.default || c),
  LoadingIndicator: () => import('../../components/elements/LoadingIndicator.vue' /* webpackChunkName: "components/loading-indicator" */).then(c => c.default || c),
  NumberPicker: () => import('../../components/elements/NumberPicker.vue' /* webpackChunkName: "components/number-picker" */).then(c => c.default || c),
  SkeletonText: () => import('../../components/elements/SkeletonText.vue' /* webpackChunkName: "components/skeleton-text" */).then(c => c.default || c),
  SvgIcon: () => import('../../components/elements/SvgIcon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => c.default || c),
  Toast: () => import('../../components/elements/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => c.default || c),
  BarChart: () => import('../../components/financials/BarChart.js' /* webpackChunkName: "components/bar-chart" */).then(c => c.default || c),
  MarginChart: () => import('../../components/financials/MarginChart.js' /* webpackChunkName: "components/margin-chart" */).then(c => c.default || c),
  PriceChart: () => import('../../components/financials/PriceChart.js' /* webpackChunkName: "components/price-chart" */).then(c => c.default || c),
  NewsArticle: () => import('../../components/news/NewsArticle.vue' /* webpackChunkName: "components/news-article" */).then(c => c.default || c),
  SidebarNewsArticle: () => import('../../components/news/SidebarNewsArticle.vue' /* webpackChunkName: "components/sidebar-news-article" */).then(c => c.default || c),
  AddEtfToPortfolioPopup: () => import('../../components/popups/AddEtfToPortfolioPopup.vue' /* webpackChunkName: "components/add-etf-to-portfolio-popup" */).then(c => c.default || c),
  AddToPortfolioPopup: () => import('../../components/popups/AddToPortfolioPopup.vue' /* webpackChunkName: "components/add-to-portfolio-popup" */).then(c => c.default || c),
  CompanyOwnershipPopup: () => import('../../components/popups/CompanyOwnershipPopup.vue' /* webpackChunkName: "components/company-ownership-popup" */).then(c => c.default || c),
  CookiePopup: () => import('../../components/popups/CookiePopup.vue' /* webpackChunkName: "components/cookie-popup" */).then(c => c.default || c),
  Feedback: () => import('../../components/popups/Feedback.vue' /* webpackChunkName: "components/feedback" */).then(c => c.default || c),
  ImageLightbox: () => import('../../components/popups/ImageLightbox.vue' /* webpackChunkName: "components/image-lightbox" */).then(c => c.default || c),
  IntroPopup: () => import('../../components/popups/IntroPopup.vue' /* webpackChunkName: "components/intro-popup" */).then(c => c.default || c),
  Popup: () => import('../../components/popups/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => c.default || c),
  ProfileEditing: () => import('../../components/popups/ProfileEditing.vue' /* webpackChunkName: "components/profile-editing" */).then(c => c.default || c),
  ShareDetailsPopup: () => import('../../components/popups/ShareDetailsPopup.vue' /* webpackChunkName: "components/share-details-popup" */).then(c => c.default || c),
  SharePopup: () => import('../../components/popups/SharePopup.vue' /* webpackChunkName: "components/share-popup" */).then(c => c.default || c),
  GeographicExposure: () => import('../../components/portfolio/GeographicExposure.vue' /* webpackChunkName: "components/geographic-exposure" */).then(c => c.default || c),
  MarketcapExposure: () => import('../../components/portfolio/MarketcapExposure.vue' /* webpackChunkName: "components/marketcap-exposure" */).then(c => c.default || c),
  PortfolioPerformanceChart: () => import('../../components/portfolio/PortfolioPerformanceChart.vue' /* webpackChunkName: "components/portfolio-performance-chart" */).then(c => c.default || c),
  PortfolioPerformanceData: () => import('../../components/portfolio/PortfolioPerformanceData.vue' /* webpackChunkName: "components/portfolio-performance-data" */).then(c => c.default || c),
  PortfolioPreview: () => import('../../components/portfolio/PortfolioPreview.vue' /* webpackChunkName: "components/portfolio-preview" */).then(c => c.default || c),
  ShareOrders: () => import('../../components/portfolio/ShareOrders.vue' /* webpackChunkName: "components/share-orders" */).then(c => c.default || c),
  ShareSpecs: () => import('../../components/portfolio/ShareSpecs.vue' /* webpackChunkName: "components/share-specs" */).then(c => c.default || c),
  Treemap: () => import('../../components/portfolio/Treemap.vue' /* webpackChunkName: "components/treemap" */).then(c => c.default || c),
  Comment: () => import('../../components/posts/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => c.default || c),
  Post: () => import('../../components/posts/Post.vue' /* webpackChunkName: "components/post" */).then(c => c.default || c),
  PostFull: () => import('../../components/posts/PostFull.vue' /* webpackChunkName: "components/post-full" */).then(c => c.default || c),
  Posts: () => import('../../components/posts/Posts.vue' /* webpackChunkName: "components/posts" */).then(c => c.default || c),
  Calendar: () => import('../../components/user/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => c.default || c),
  PortfolioChart: () => import('../../components/user/PortfolioChart.js' /* webpackChunkName: "components/portfolio-chart" */).then(c => c.default || c),
  CompanyDescription: () => import('../../components/company/CompanyDescription.vue' /* webpackChunkName: "components/company-description" */).then(c => c.default || c),
  CompanyDetails: () => import('../../components/company/CompanyDetails.vue' /* webpackChunkName: "components/company-details" */).then(c => c.default || c),
  CompanyNetwork: () => import('../../components/company/CompanyNetwork.vue' /* webpackChunkName: "components/company-network" */).then(c => c.default || c),
  CompanyNews: () => import('../../components/company/CompanyNews.vue' /* webpackChunkName: "components/company-news" */).then(c => c.default || c),
  FinancialStanding: () => import('../../components/company/FinancialStanding.vue' /* webpackChunkName: "components/financial-standing" */).then(c => c.default || c),
  InsiderTransactions: () => import('../../components/company/InsiderTransactions.vue' /* webpackChunkName: "components/insider-transactions" */).then(c => c.default || c),
  Liquidity: () => import('../../components/company/Liquidity.vue' /* webpackChunkName: "components/liquidity" */).then(c => c.default || c),
  Margins: () => import('../../components/company/Margins.vue' /* webpackChunkName: "components/margins" */).then(c => c.default || c),
  Ownership: () => import('../../components/company/Ownership.vue' /* webpackChunkName: "components/ownership" */).then(c => c.default || c),
  PriceHistory: () => import('../../components/company/PriceHistory.vue' /* webpackChunkName: "components/price-history" */).then(c => c.default || c),
  Publications: () => import('../../components/company/Publications.vue' /* webpackChunkName: "components/publications" */).then(c => c.default || c),
  Valuation: () => import('../../components/company/Valuation.vue' /* webpackChunkName: "components/valuation" */).then(c => c.default || c),
  Error: () => import('../../components/Error.vue' /* webpackChunkName: "components/error" */).then(c => c.default || c),
  Footer: () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => c.default || c),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
  NavBar: () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => c.default || c),
  NotificationCenter: () => import('../../components/NotificationCenter.vue' /* webpackChunkName: "components/notification-center" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
